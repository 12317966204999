import React from 'react';
import Layout from '../../components/Layout';
import RapydComplete from './RapydComplete';

const title = 'Payment Complete';

export default async function action({ store }) {
    return {
      title,
      component: <Layout><RapydComplete title={title} /></Layout>,
    };
  };
