import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

// Redux
import { connect } from "react-redux";

// Redux Form
import { Field, reduxForm } from "redux-form";

import { Button, FormGroup, FormControl } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Payout.css";
import cs from "../../../components/commonStyle.css";

import submit from "./submit";

// Locale
import messages from "../../../locale/messages";
import Loader from "../../Loader/Loader";

class BankAccount extends Component {
  static propTypes = {
    handleSubmit: PropTypes.any.isRequired,
    previousPage: PropTypes.any.isRequired,
    siteName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error, dirty },
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.spaceBottom4}>
        <label>{label}</label>
        <FormControl
          {...input}
          componentClass="input"
          className={cx(cs.formControlInput, "commonInputPaddingRTL")}
          placeholder={placeholder}
          maxLength={255}
        />
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.spaceBottom4}>
        <label>{label}</label>
        <FormControl
          componentClass="select"
          {...input}
          className={cs.formControlSelect}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  render() {
    const { handleSubmit, pristine, previousPage, submitting, payoutLoading } =
      this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div
        className={cx(
          "inputFocusColor",
          cs.commonBorderSection,
          "whiteBgColor"
        )}
      >
        <form onSubmit={handleSubmit(submit)}>
          <h2 className={cx(cs.commonTotalText, cs.paddingBottom4)}>
            {formatMessage(messages.addPayout)}
          </h2>
          <h3>{formatMessage(messages.bankAccount)}</h3>
          <p className={cx(cs.commonMediumText, cs.paddingBottom3)}>
          {formatMessage(messages.bankAccountIntro)}
          </p>

          <div
            className={cx(
              s.payoutTitleFlex,
              s.spaceBetWeen,
              s.flexColumMobile,
              s.alignItemBaseLine
            )}
          >
            <Field
              name="bank"
              component={this.renderField}
              label={""}
              placeholder={formatMessage(messages.bank)}
            />

            <Field
              name="ledger"
              component={this.renderField}
              label={""}
              placeholder={formatMessage(messages.ledger)}
            />

            <Field
              name="accountNo"
              component={this.renderField}
              label={""}
              placeholder={formatMessage(messages.accountNo)}
            />
          </div>
          <div className={cx(s.btnFlex, cs.spaceTop4)}>
            <Button
              className={cx(cs.btnPrimaryBorder, s.btnRight, "payoutBackRTL")}
              onClick={previousPage}
            >
              <FormattedMessage {...messages.back} />
            </Button>
            <span className={"arButtonLoader"}>
              <Loader
                type={"button"}
                buttonType={"submit"}
                label={formatMessage(messages.finish)}
                className={cs.btnPrimary}
                show={pristine || submitting || payoutLoading}
                disabled={pristine || submitting || payoutLoading}
              />
            </span>
          </div>
        </form>
      </div>
    );
  }
}

BankAccount = reduxForm({
  form: "PayoutForm", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(BankAccount);

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  availableCurrencies: state.currency.availableCurrencies,
  payoutLoading: state.reservation.payoutLoading,
  base: state.currency.base,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, cs)(connect(mapState, mapDispatch)(BankAccount))
);
