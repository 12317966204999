import fetch from "../../fetch";
import { rapydPayPath, headers } from "./constants";

export async function sendRapydPayment(
  amount,
  currency,
  full_name,
  reservationId
) {
  // calls app.post() in rapydPayment
  const response = await fetch(rapydPayPath, {
    method: "POST",
    headers,
    body: JSON.stringify({ amount, currency, full_name, reservationId }),
    credentials: "include",
  });

  const jsonData = await response.json();

  const redirect = jsonData?.data?.body?.data?.redirect_url || "";

  if (jsonData?.status == 200 && redirect) {
    window.open(redirect);

    return await {
      status: 200,
      errorMessage: null,
    };
  } else {
    return await {
      status: 400,
      errorMessage: null,
    };
  }
}

export async function getRapydPaymentResult(reservationId) {
  const response = await fetch("/rapyd-booking-complete", {
    method: "POST",
    headers,
    body: JSON.stringify({ reservationId }),
    credentials: "include",
  });

  const res = await response.json();

  if (res?.status === 200) {
    return {
      status: 200,
      errorMessage: null,
    };
  } else {
    return {
      status: 400,
      errorMessage: "Error",
    };
  }
}
