import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";

import SidePanel from "./SidePanel";
import FooterButton from "./FooterButton";
import CommonFormComponent from "../CommonField/CommonFormComponent";
import CustomFormComponent from "../CommonField/CustomFormComponent";

import update from "./update";
import messages from "../../locale/messages";
import validate from "./validate";

import s from "./ListPlaceStep1.css";
import cs from "../commonStyle.css";

import LicencePlateInput from "./licence-plate-input/licence-plate-input.js";

import { getVehicleInformation } from "../../../src/core/carRegister/carRegister.js";
import { debounce } from "lodash";
import showToaster from "../../helpers/toasterMessages/showToaster.js";
import CircleLoader from "../Loader/CircleLoader.js";
import { getListingFieldsValues } from "../../actions/getListingFieldsValues.js";

class Page2 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      carType: [],
      year: "",
      make: "",
      model: "",
      transmission: "",
      odometer: [],
      isModelValue: "",
      licencePlate: "",
      hasVehicleInformation: false,
      insuranceCompanyName: "",
      isLoading: false,
      vehicleRegistrationPlate: "",
    };
    this.inputValue = debounce(this.inputValue, 1000);
  }
  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        odometer: listingFields.odometer,
      });
    }
  }

  componentDidMount() {
    const { valid, listingFields, makeName, inEditModeData } = this.props;
    if (inEditModeData?.make && inEditModeData.model && inEditModeData.year) {
      this.setVehicleInformationOnEdit();
    }

    let ModelValues =
      listingFields &&
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == makeName);
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    if (listingFields != undefined) {
      this.setState({
        isModelValue: "",
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields, makeName } = nextProps;
    let ModelValues =
      listingFields &&
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == makeName);
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        odometer: listingFields.odometer,
        isModelValue: "",
      });
    }
  }

  changeModelType = async (e) => {
    const { change, listingFields } = this.props;
    let newValue = e.target.value;
    let ModelValue =
      listingFields.model &&
      listingFields.model.filter((o) => o.makeType == newValue);
    this.setState({
      isModelValue: "",
      makeValue: "",
      isTrimValue: [],
    });

    if (ModelValue && ModelValue.length > 0 && ModelValue[0].isEnable == "1") {
      change("model", ModelValue && ModelValue[0].id);
    } else {
      change("model", "");
    }
  };

  onChange = (value) => {
    if ((value?.length || 0) > 0) {
      this.setState({ isLoading: true });
      this.setState({ hasVehicleInformation: false });
      this.inputValue(value);
    }
  };

  inputValue = (value) => {
    if ((value?.length || 0) > 0) {
      this.setLicencePlate(value);
    }
  };

  setLicencePlate = (licencePlateInput) => {
    this.setState({ licencePlate: licencePlateInput });
    this.setVehicleInformation(licencePlateInput);
  };

  setVehicleInformation = async (licencePlateInput) => {
    const { status, data, errorMessage } = await getVehicleInformation(
      licencePlateInput
    );

    if (status == 200) {
      this.props.dispatch(change("ListPlaceStep1", "make", data.make));
      this.props.dispatch(change("ListPlaceStep1", "model", data.model));
      this.props.dispatch(change("ListPlaceStep1", "year", data.year));
      this.props.dispatch(
        change("ListPlaceStep1", "transmission", data?.transmissionType)
      );

      this.props.dispatch(
        change(
          "ListPlaceStep1",
          "insuranceCompanyName",
          data?.insuranceCompanyName
        )
      );
      this.props.dispatch(
        change("ListPlaceStep1", "vehicleRegistrationPlate", licencePlateInput)
      );
      this.setState({
        make: data.make,
        year: data.year,
        model: data?.model,
        transmission: data?.transmissionType,
        insuranceCompanyName: data?.insuranceCompanyName,
        vehicleRegistrationPlate: licencePlateInput,
      });
      this.setState({ hasVehicleInformation: true });
    }

    if (status > 299) {
      showToaster({
        messageId: null,
        toasterType: "error",
        requestMessage: errorMessage,
      });
    }
    this.setState({ isLoading: false });
    getListingFieldsValues();
  };

  setVehicleInformationOnEdit = () => {
    const { listingFields, inEditModeData } = this.props;

    let model = listingFields?.model?.filter(
      (model) => model?.id === inEditModeData?.model
    )?.[0];

    if (!model) {
      model = listingFields?.model?.filter(
        (model) =>
          `${model?.itemName}`?.toLowerCase() ===
          `${inEditModeData?.model}`?.toLowerCase()
      )?.[0];
    }

    let make = listingFields?.make?.filter(
      (make) => make?.id === inEditModeData?.make
    )?.[0];

    if (!make) {
      make = listingFields?.make?.filter(
        (make) =>
          `${make?.itemName}`?.toLowerCase() ===
          `${inEditModeData?.make}`?.toLowerCase()
      )?.[0];
    }

    let year = listingFields?.year?.filter(
      (year) => year?.id === inEditModeData?.year
    )?.[0];

    if (!year) {
      year = listingFields?.year?.filter(
        (year) => year?.itemName === `${inEditModeData?.year}`
      )?.[0];
    }

    const transmission = inEditModeData?.transmission;
    const insuranceCompanyName = inEditModeData?.insuranceCompanyName;
    const vehicleRegistrationPlate = inEditModeData?.vehicleRegistrationPlate;

    const odometer = listingFields?.odometer?.filter(
      (odometer) => odometer?.id === inEditModeData?.odometer
    );

    const carType = listingFields?.carType?.filter(
      (carType) => carType?.id === inEditModeData?.carType
    );

    this.props.dispatch(change("ListPlaceStep1", "make", make?.id));
    this.props.dispatch(change("ListPlaceStep1", "model", model?.id));
    this.props.dispatch(change("ListPlaceStep1", "year", year?.id));
    this.props.dispatch(change("ListPlaceStep1", "odometer", odometer?.id));
    this.props.dispatch(change("ListPlaceStep1", "carType", carType?.id));
    this.props.dispatch(
      change("ListPlaceStep1", "insuranceCompanyName", insuranceCompanyName)
    );
    this.props.dispatch(
      change(
        "ListPlaceStep1",
        "vehicleRegistrationPlate",
        vehicleRegistrationPlate
      )
    );

    this.setState({
      make: make?.itemName,
      year: year?.itemName,
      model: model?.itemName,
      transmission: Number(transmission),
      insuranceCompanyName: insuranceCompanyName,
      vehicleRegistrationPlate: vehicleRegistrationPlate,
    });

    this.setState({ hasVehicleInformation: true });
    getListingFieldsValues();
  };

  render() {
    const {
      handleSubmit,
      previousPage,
      nextPage,
      existingList,
      formPage,
      step,
    } = this.props;
    const {
      isDisabled,
      make,
      year,
      odometer,
      carType,
      model,
      insuranceCompanyName,
      transmission,
      vehicleRegistrationPlate,
      hasVehicleInformation,
      isLoading,
    } = this.state;

    const { formatMessage } = this.props.intl;
    let path = "index";
    if (existingList) {
      path = "home";
    }

    return (
      <div className={cx(s.stepGrid, "stepGridRTL")}>
        <SidePanel
          title={formatMessage(messages.step1HeadingNew)}
          landingContent={formatMessage(messages.whatKindOfPlaceListing)}
        />
        <div>
          <form onSubmit={handleSubmit}>
            <div className={s.landingMainContent}>
              <LicencePlateInput callback={(lp) => this.onChange(lp)} />
              {!hasVehicleInformation && (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircleLoader show={isLoading} />
                </div>
              )}

              {hasVehicleInformation && (
                <>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage
                        {...messages.vehicleRegistrationPlate}
                      />
                    </ControlLabel>
                    <Field
                      name="vehicleRegistrationPlate"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      {...{
                        inputValue: vehicleRegistrationPlate,
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.year} />
                    </ControlLabel>
                    <Field
                      name="year"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      {...{
                        inputValue: year,
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.modelLabel} />
                    </ControlLabel>
                    <Field
                      name="model"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      to
                      {...{
                        inputValue: model,
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.whatTypeOfProperty} />
                    </ControlLabel>
                    <Field
                      name="make"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      {...{
                        inputValue: make,
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.isPersonalHome} />
                    </ControlLabel>
                    <Field
                      name="transmission"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      {...{
                        inputValue:
                          transmission === 1
                            ? formatMessage(messages.Manuall)
                            : formatMessage(messages.Automatic),
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.insuranceCompanyName} />
                    </ControlLabel>
                    <Field
                      name="insuranceCompanyName"
                      readOnly
                      props={{
                        type: "text",
                      }}
                      component={CustomFormComponent}
                      {...{
                        inputValue: insuranceCompanyName,
                      }}
                      inputClass={cx(s.jumboSelect, s.marginBottompageTwo)}
                    ></Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.whatGuestHave} />
                    </ControlLabel>
                    <Field
                      name="carType"
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {carType.map((value, key) => {
                        return (
                          value.isEnable == 1 && (
                            <option value={value.id} key={key}>
                              {value.itemName}
                            </option>
                          )
                        );
                      })}
                    </Field>
                  </FormGroup>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.odometer} />
                    </ControlLabel>
                    <Field
                      name="odometer"
                      component={CommonFormComponent}
                      inputClass={cx(
                        s.formControlSelect,
                        s.jumboSelect,
                        s.marginBottompageTwo
                      )}
                    >
                      {odometer?.map((value, key) => {
                        return (
                          value.isEnable == 1 && (
                            <option value={value?.id} key={key}>
                              {value?.itemName}
                            </option>
                          )
                        );
                      })}
                    </Field>
                  </FormGroup>
                </>
              )}
            </div>
            <FooterButton
              isDisabled={isDisabled}
              nextPage={nextPage}
              previousPage={previousPage}
              nextPagePath={"location"}
              previousPagePath={path}
              formPage={formPage}
              step={step}
            />
          </form>
        </div>
      </div>
    );
  }
}

Page2 = reduxForm({
  form: "ListPlaceStep1",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update,
})(Page2);

const selector = formValueSelector("ListPlaceStep1");

const mapState = (state) => ({
  existingList: state?.location?.isExistingList,
  listingFields: state?.listingFields?.data,
  makeName: selector(state, "make"),
  modelValue: selector(state, "model"),
  inEditModeData: state.form.ListPlaceStep1.values,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, cs)(connect(mapState, mapDispatch)(Page2))
);
