import React, { useEffect, useState } from "react";

import FormGroup from "react-bootstrap/lib/FormGroup";

import { FormControl } from "react-bootstrap";

export default function LicencePlateInput({ callback }) {
  const [licencePlate, setLicencePlate] = useState();

  useEffect(() => {
    callback(licencePlate);
  }, [licencePlate]);

  return (
    <FormGroup>
      <label>Licence Plate</label>
      <FormControl
        placeholder={"Licence plate"}
        type="text"
        onChange={(e) => {
          setLicencePlate(e.target.value);
        }}
      />
    </FormGroup>
  );
}
