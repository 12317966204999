import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { COMMON_TEXT_COLOR } from "../../../constants/index";
import moment from "moment";

class CarRegistrationToAccountant extends Component {
  static propTypes = {
    content: PropTypes.shape({
      name: PropTypes.string,
      SSN: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      lastUpdatedAt: PropTypes.string,
      vehicleRegistrationPlate: PropTypes.string,
      insuranceCompanyName: PropTypes.string,
      bank: PropTypes.string,
      ledger: PropTypes.string,
      accountNo: PropTypes.string,
    }),
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const {
      content: {
        SSN,
        name,
        email,
        phone,
        lastUpdatedAt,
        vehicleRegistrationPlate,
        insuranceCompanyName,
      },
    } = this.props;

    return (
      <Layout>
        <Header
          color="rgb(255, 90, 95)"
          backgroundColor="#F7F7F7"
          siteName={"Thor Rentals"}
        />

        <Body textStyle={textStyle}>
          <div>{`This registartion was made on ${moment(lastUpdatedAt).format(
            "LLL"
          )}`}</div>
          <EmptySpace height={20} />
          <div>Owner:</div>
          <div>{`Vehicle Registration Number: ${vehicleRegistrationPlate}`}</div>
          <div>{`Insurance Company Name: ${insuranceCompanyName}`}</div>
          <div>{`Name: ${name}`}</div>
          <div>{`Car owner Social Security Number: ${SSN}`}</div>
          <div>{`Payout Bank Account Number: ${bank}-${ledger}-${accountNo}`}</div>
          <div>{`Phone: ${phone}`}</div>
          <div>{`Email: ${email}`}</div>
          <EmptySpace height={20} />

          <EmailSignature siteName={"Thor Rentals"} />
        </Body>
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CarRegistrationToAccountant;
